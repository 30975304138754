import React, { Component } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { graphql, StaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import axios from "axios"
import { FaFacebookSquare, FaInstagram, FaTwitterSquare } from "react-icons/fa"

// Components
import GA from "../components/ga"
import Layout from "../components/layout"
import CheckMark from "../components/valid"
import Sidebar from "../components/sidebar"
import NewsLetter from "../components/newsLetter"
import Hero from "../components/hero"
import InnerWrapper from "../components/innerWrapper"
import SEO from "../SEO"
// import SidebarCart from "../components/sidebarCart"

// Styles
import styles from "../components/scss/contact.module.scss"
import secondaryStyles from "./scss/contact.module.scss"
class Contact extends Component {
  state = {
    name: "",
    email: "",
    tel: "",
    message: "",
    infoMessage: "",
    checked: false,
    loading: false,
  }

  handleSubmitNewsletter = async (evt) => {
    const result = await addToMailchimp(this.state.email.value, {
      NAME: this.state.name.value,
    })

    this.setState({ msg: result.msg })
    setTimeout(() => {
      this.setState({ email: "", msg: null })
    }, 3000)
  }

  handleSubmit = (evt) => {
    evt.preventDefault()

    if (this.state.checked) {
      this.handleSubmitNewsletter()
    }

    this.setState({ loading: true })
    const form = evt.target
    const axiosConfig = {
      header: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
    axios
      .post(
        "/.netlify/functions/sendEmail",
        {
          name: this.state.name.value,
          email: this.state.email.value,
          tel: this.state.tel.value,
          subject: "New Email",
          message: this.state.message.value,
        },
        axiosConfig
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            name: "",
            email: "",
            tel: "",
            message: "",
            infoMessage: "Thank you for your message",
            loading: false,
          })
          form.reset()
          setTimeout(() => {
            this.setState({
              infoMessage: "",
            })
          }, 5000)
        }
      })
      .catch((err) => {
        this.setState({
          infoMessage: "Opps, Something went wrong.. Please try again",
        })
        setTimeout(() => {
          this.setState({
            infoMessage: "",
          })
        }, 5000)
      })
  }

  handleChange = (evt) => {
    const isValid = evt.target.checkValidity()
    this.setState({
      [evt.target.name]: {
        value: evt.target.value,
        valid: isValid,
      },
    })
  }

  handleCheckbox = (evt) => {
    this.setState({ checked: !this.state.checked })
  }
  render() {
    return (
      <>
        <SEO title="Brophy Brothers | Contact" />
        <GA />
        <Layout>
          <Sidebar />
          {/* <SidebarCart /> */}
          <Hero
            text=""
            image={this.props.data.contentfulContactPageHero.heroImage.file.url}
            buttons={false}
          />
          <section className={`section ${secondaryStyles.contact}`}>
            <div className={secondaryStyles.container}>
              <InnerWrapper width={1200}>
                <div className="banner">
                  <h2 className="ribbon">
                    <span className="top">&nbsp;</span>
                    <span className="text">
                      {this.props.data.contentfulContactSection.title}
                    </span>
                    <span className="bottom">&nbsp;</span>
                  </h2>
                </div>

                <div className={styles.body}>
                  {documentToReactComponents(
                    this.props.data.contentfulContactSection.body.json
                  )}
                </div>

                <div className={styles.map}>
                  <div
                    className={styles.googleMap}
                    dangerouslySetInnerHTML={{
                      __html: `<iframe   loading="lazy"
       title="Google Maps" frameborder="0" style="border:0"
src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJkWDZQffywokRa-Vi4O-LIuY&key=AIzaSyBOw06PZeBx-bOnxbWoO_8jTdqmfIGUDxM" allowfullscreen></iframe>`,
                    }}
                  ></div>

                  <form className={styles.form} onSubmit={this.handleSubmit}>
                    <h3>Contact Us</h3>
                    <label htmlFor="name">Name (required)</label>
                    <div role="group">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        minLength="2"
                        value={this.state.name.value || ""}
                        required
                        onChange={this.handleChange}
                      />
                      <CheckMark value={this.state.name.valid} />
                    </div>

                    <label htmlFor="email">Email (required)</label>
                    <div role="group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        required
                        value={this.state.email.value || ""}
                        onChange={this.handleChange}
                      />
                      <CheckMark value={this.state.email.valid} />
                    </div>

                    <label htmlFor="tel">Phone Number (optional)</label>
                    <div role="group">
                      <input
                        type="tel"
                        name="tel"
                        id="tel"
                        pattern="[0-9]{10,14}"
                        value={this.state.tel.value || ""}
                        onChange={this.handleChange}
                      />
                      <CheckMark value={this.state.tel.valid} />
                    </div>

                    <label htmlFor="message">Your message (required)</label>
                    <div role="group">
                      <textarea
                        name="message"
                        id="message"
                        cols="30"
                        rows="10"
                        required
                        value={this.state.message.value || ""}
                        onChange={this.handleChange}
                      />
                      <CheckMark value={this.state.message.valid} />
                    </div>

                    {/* <div role="group">
                      <label className={styles.container}>
                        Signup to our Newsletter (Optional)
                        <input
                          type="checkbox"
                          id="newsletter"
                          name="newsletter"
                          checked={this.state.checked}
                          onChange={this.handleCheckbox}
                        />
                        <span className={styles.checkmark}></span>
                      </label>
                    </div> */}

                    <div role="group">
                      <label htmlFor="newsletter">
                        Signup to our Newsletter (Optional)
                      </label>
                      <input
                        type="checkbox"
                        id="newsletter"
                        name="newsletter"
                        checked={this.state.checked}
                        onChange={this.handleCheckbox}
                      />
                    </div>

                    <button className={styles.submit} type="submit">
                      {this.state.loading ? "Sending..." : "Submit"}
                    </button>
                    <p className={styles.infoMessage}>
                      {this.state.infoMessage}
                    </p>
                  </form>
                </div>
                <div className="banner">
                  <h2 className="ribbon">
                    <span className="top">&nbsp;</span>
                    <span className="text">Social Media</span>
                    <span className="bottom">&nbsp;</span>
                  </h2>
                </div>
                <div className={styles.social}>
                  <ul>
                    <li>
                      <a
                        href={
                          this.props.data.contentfulFooterSection.facebookLink
                        }
                        aria-label="Link to Brophy Brothers Facebook page"
                      >
                        <FaFacebookSquare className={styles.facebook} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          this.props.data.contentfulFooterSection.twitterLink
                        }
                        aria-label="Link to Brophy Brothers Twitter page"
                      >
                        <FaTwitterSquare className={styles.twitter} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          this.props.data.contentfulFooterSection.instagramLink
                        }
                        aria-label="Link to Brophy Brothers Instagram page"
                      >
                        <FaInstagram className={styles.instagram} />
                      </a>
                    </li>
                  </ul>
                </div>
              </InnerWrapper>
            </div>
          </section>
          <NewsLetter />
        </Layout>
      </>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      {
        contentfulContactSection {
          title
          body {
            json
          }
        }
        contentfulContactPageHero {
          text
          heroImage {
            file {
              url
            }
          }
        }
        contentfulFooterSection {
          facebookLink
          twitterLink
          instagramLink
          addressLine1
          addressLine2
          addressLine3
        }
      }
    `}
    render={(data) => <Contact data={data} {...props} />}
  />
)
